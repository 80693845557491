import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderThree from '../common/header/HeaderThree';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import AboutSix from '../component/about/AboutSix';
import FaqOne from '../component/faq/FaqOne';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const AboutUs = () => {

    return (
        <>
        <SEO title="Websites vom Profi" />
        <ColorSwitcher />
            <main className="main-wrapper">
            <FloatingWhatsApp
            phoneNumber="4915140360833"
            accountName="Max"
            chatMessage="Hi ich bin Max👋 
            Wie kann ich dir helfen? :)"
            avatar="/images/banner/profile_pic1.png"
            allowEsc
            allowClickAway
            notification
            notificationSound
      />
            <HeaderThree />
                {/* <BcrumbBannerOne 
                title="About Webvizion"
                paragraph ="We design and develop web and mobile applications for our clients worldwide."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/aboutrocket.png"
                /> */}
                <AboutSix />
                <AboutFour />
                 <div className="section section-padding">
                <div className="container">
                <SectionTitle 
                    subtitle="Our Service"
                    title="Dienstleistungen"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div>
                
                <AboutThree />
                <AboutFive />
                {/* <ProcessOne /> */}
                
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;