import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-2.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Philosophie</span>
                        <h2>Nicht nur eine Website</h2>  
                        <p>In unserer Webdesign-Agentur sind wir der Meinung, dass eine Website mehr ist als nur eine Ansammlung von Seiten. Sie ist ein Spiegelbild Ihres Unternehmens und ein wichtiges Instrument, um Kunden zu gewinnen und zu binden.

Wir verfolgen beim Webdesign einen ganzheitlichen Ansatz, der nicht nur die Ästhetik, sondern auch die Funktionalität und Benutzerfreundlichkeit Ihrer Website berücksichtigt. Unser Team aus erfahrenen Designern und Entwicklern arbeitet eng mit Ihnen zusammen, um Ihre Geschäftsziele zu verstehen und eine Website zu erstellen, die nicht nur gut aussieht, sondern auch zu Ergebnissen führt.</p>
                        <Link to="/contact" className="axil-btn btn-large btn-fill-primary">Kostenlose Beratung</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;