import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderThree from '../common/header/HeaderThree';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Impressum" />
            <ColorSwitcher />
            <main className="main-wrapper">
            <HeaderThree />
                <BreadCrumbOne 
                title="Impressum"
                page="Impressum"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">7. November, 2022.</h5>
                                </div>
                                <h4>Angaben gemäß § 5 TMG</h4>
                                <p>Maximilian Hersam <br />Schussenstraße 16<br />88250 Weingarten
                                </p>
                                <h4>Kontakt</h4>
                                <p>Telefon: +4915140360833 <br/>E-Mail: info@webvizion.de </p>
                                <h4>Berufsbezeichnung</h4>
                                <p>Berufsbezeichnung: WebentwicklerIn</p>

                                <h4>Streitschlichtung</h4>
                                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.  <br />
                                Unsere E-Mail-Adresse finden Sie oben im Impressum.<br />

                                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                               
                                <h4>Urheberrecht</h4>
                                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                                <br />Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;