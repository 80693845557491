import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'

import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBehance, FaPhone, FaFax, FaWhatsapp, FaEnvelope } from "react-icons/fa";



const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {/* <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form> */}
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/website-erstellung-service"}>Service</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>Kontakt</Link></li>
                            
                        
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                              
                                <address className="address">
                                    <span className="title">E-Mail: </span>
                                    <a href="tel:12125553333" className="tel"><FaEnvelope /> info@webvizion.de</a>
                                </address>
                                <address className="address">

                                    <span className="title">WhatsApp: </span>
                                    <a aria-label="Chat on WhatsApp" href="https://wa.me/4915140360833"> <img alt="Chat on WhatsApp" src="/images/banner/WhatsAppButtonGreenSmall.png" /> </a>
 
                                </address>
                            </div>
                            {/* <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/"><FaFacebookF /></a>
                                        </li>

                                        <li>
                                            <a href="https://twitter.com/"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.behance.net/"><FaBehance /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
