import React from 'react'
import './bannerEleven.scss'
import { Link } from 'react-router-dom';

const BannerEleven = () => {
  return (	 
	  <div className='section_eleven'>
		{/* <div className='topbar'>
			<h2>Limitiertes Angebot bis 31.12.2022</h2>
		</div> */}
			  <div className='eleven_container'>
				  <div className='eleven_content'>
					  <h1>Webdesign, das überzeugt.</h1>
					  <p>Professioneller Internet-Auftritt und Online Marketing Strategien aus einer Hand.</p>
					  <Link to="/contact">
					  <button class="glow-on-hover3 bounce-top" id='btn1' type="button">Jetzt starten</button>
					  </Link>
				  </div>
				  <div className='website_section'>
					  <div class="website-slider">
						  <div class="website-slide-track">
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/freedin.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/elefant.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/stonemason.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_light.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/nutritionbanner.png"} alt="webdesign" />
							  </div>
							
						  </div>
					  </div>
					  <div class="website-slider2">
						  <div class="website-slide-track2">
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/freedin.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/elefant.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/montblanc.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/stonemason.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_light.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/montblanc_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
							  	<img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
							  	<img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
							  	<img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
							  	<img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/nutritionbanner.png"} alt="webdesign" />
							  </div>
							  <div class="website">
							  	<img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/elefant.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
						  </div>
					  </div>


					  <div class="website-slider">
						  <div class="website-slide-track">
						  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/freedin.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/elefant.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/stonemason.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_light.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/nutritionbanner.png"} alt="webdesign" />
							  </div>
						  </div>
					  </div>

					  <div class="website-slider2">
						  <div class="website-slide-track2">
							  <div class="website">
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/freedin.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/elefant.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/stonemason.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_light.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/webdesign_dark.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/architecture.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/modernart.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/marketingagentur.png"} alt="webdesign" />
							  </div>
							  <div class="website">
								  <img className="test-only" src={process.env.PUBLIC_URL + "/images/banner/nutritionbanner.png"} alt="webdesign" />
							  </div>
							  </div>
						  </div>

					  </div>

				  </div>
			  </div>
			  </div>
		  
  )
}

export default BannerEleven