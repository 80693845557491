import React from 'react'
import { Link } from 'react-router-dom'
import './bannerEight.scss'

const BannerEight = () => {
  return (
    <div className='eight_section'>
        <div className='eight_container'>
        <div className='eight_content'>
            <h1>Top-notch Webdesign.</h1> 
            <p>Sieh dir unsere bisherigen Projekte an und überzeug dich selbst.</p> 
            <Link to="/webdesign-projekte">
            <button class="glow-on-hover2" type="button">Projekte ansehen</button>
            </Link>
        </div>
        <div className='eight_templates'>
        <div className='item'>
                <a href="https://pvpictures.de/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/banner/pvpictures.jpg"} alt="Pvpictures" />
                </a>
                <h4>PV Pictures</h4>
                <p>Hochzeitsfotografie</p>
            </div>
        <div className='item'>
                <a href="https://montblanc-pen.com/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/banner/montblanc-pen.jpg"} alt="montblanc" />
                </a>
                <h4>Montblanc-Pen</h4>
                <p>Größter Montblanc Händler Europas</p>
            </div>
            <div className='item'> 
                <a href="https://bodyconcept-coaching.de" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/banner/website_bodyconcept.jpg"} alt="Bodyconcept" />
                </a>
                <h4>Bodyconcept Coaching</h4>
                <p>Personal Coaching</p>
            </div>
            <div className='item'>
                <a href="https://rong-gruppe.de/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/banner/website_rong.jpg"} alt="Rong" />
                </a>
                <h4>Rong Trockenbau</h4>
                <p>Trockenbau und Ausbau</p>
            </div>
            <div className='item'>
                <a href="https://pcs-consult.ch/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/banner/website_pcs.jpg"} alt="PCS" />
                </a>
                <h4>PCS Consulting</h4>
                <p>Unternehmensberatung</p>
            </div>
            <div className='item'>
                <a href="https://steffenjaeckle.de/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/banner/website_jaeckle.jpg"} alt="Jaeckle" />
                </a>
                <h4>Jaeckle Consulting</h4>
                <p>Unternehmensberatung und Digitalisierung</p>
            </div>
            
            
            
          


        </div>
        </div>
    </div>
  )
}

export default BannerEight