import React from 'react'
import { Link } from 'react-router-dom'
import './bannerTen.scss';


const BannerTen = () => {
  return (
    <div className='ten_section'>
        <div className='ten_container'>
            <div className='ten_content'>
                <h1>"So sollte es sein!"</h1> 
                <p>Wir konnten bereits zahlreiche Kunden von uns überzeugen. Lass auch du dich begeistern.</p> 
                <Link to="/contact">
                <button class="glow-on-hover" type="button">Überzeugt mich</button>
                </Link>
            </div>

    
     
<div className='testimonial_section'>
<div class="testimonial-slider2">

	<div class="testimonial-slide-track2">
		<div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-jaeckle.png"} alt="Laptop" />
            <h4>Steffen Jäckle</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>5 Sterne – Alles top! Sehr schnelle Reaktions- und Umsetzungszeit + unkomplizierte Kommunikation: So sollte es sein!</p>
            <p className='company_name'>Jäckle Consulting</p>

		</div>
        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-pv.png"} alt="Laptop" />
            <h4>Patrick Vögele</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Ich bin durch eine Empfehlung endlich bei einer Agentur "angekommen", die mich und meine Wünsche an eine neue Homepage verstanden und verwirklicht hat. Die Zusammenarbeit war in jeder Hinsicht kompetent, zuverlässig und schnell. Take a look: pvpictures.de</p>
            <p className='company_name'>PV Pictures</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-standart.png"} alt="Laptop" />
            <h4>Chantal Pfund</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Von Anfang bis Ende war unsere Erfahrung professionell, reaktionsschnell und wirklich freundlich. Es wurde sich die Zeit genommen, unsere Vorstellungen zu verstehen und gaben uns wertvolle Einblicke und Vorschläge, wie man die Website noch besser machen kann. Das Endergebnis war eine schöne Website, die unsere Erwartungen übertroffen hat. Ich kann diese Webdesign-Agentur jedem wirklich jedem weiterempfehlen.</p>
            <p className='company_name'>Unternehmensgruppe Rong</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-bb.png"} alt="Laptop" />
            <h4>Bianca Both</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Ich bin mehr als zufrieden mit dem Design, der Zusammenarbeit und der Verwirklichung meiner Wünsche. Ich liebe es und kann es nur weiterempfehlen!</p>
            <p className='company_name'>Biancaboth.de</p>
		</div>

        

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-jaeckle.png"} alt="Laptop" />
            <h4>Steffen Jäckle</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>5 Sterne – Alles top! Sehr schnelle Reaktions- und Umsetzungszeit + unkomplizierte Kommunikation: So sollte es sein!</p>
            <p className='company_name'>Jäckle Consulting</p>
		</div>
	</div>

    <div className='desktop_only'>
    <div class="testimonial-slide-track3">
    
    
    <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-standart.png"} alt="Laptop" />
            <h4>Chantal Pfund</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Von Anfang bis Ende war unsere Erfahrung professionell, reaktionsschnell und wirklich freundlich. Es wurde sich die Zeit genommen, unsere Vorstellungen zu verstehen und gaben uns wertvolle Einblicke und Vorschläge, wie man die Website noch besser machen kann. Das Endergebnis war eine schöne Website, die unsere Erwartungen übertroffen hat. Ich kann diese Webdesign-Agentur jedem wirklich jedem weiterempfehlen.</p>
            <p className='company_name'>Unternehmensgruppe Rong</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-pv.png"} alt="Laptop" />
            <h4>Patrick Vögele</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Ich bin durch eine Empfehlung endlich bei einer Agentur "angekommen", die mich und meine Wünsche an eine neue Homepage verstanden und verwirklicht hat. Die Zusammenarbeit war in jeder Hinsicht kompetent, zuverlässig und schnell. Take a look: pvpictures.de</p>
            <p className='company_name'>PV Pictures</p>
		</div>


       
        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-jaeckle.png"} alt="Laptop" />
            <h4>Steffen Jäckle</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>5 Sterne – Alles top! Sehr schnelle Reaktions- und Umsetzungszeit + unkomplizierte Kommunikation: So sollte es sein!</p>
            <p className='company_name'>Jäckle Consulting</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-bb.png"} alt="Laptop" />
            <h4>Bianca Both</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Ich bin mehr als zufrieden mit dem Design, der Zusammenarbeit und der Verwirklichung meiner Wünsche. Ich liebe es und kann es nur weiterempfehlen!</p>
            <p className='company_name'>Biancaboth.de</p>
		</div>

 

        
	</div>
    </div>

    <div className='desktop_only'>
    <div class="testimonial-slide-track2">

    <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-pv.png"} alt="Laptop" />
            <h4>Patrick Vögele</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Ich bin durch eine Empfehlung endlich bei einer Agentur "angekommen", die mich und meine Wünsche an eine neue Homepage verstanden und verwirklicht hat. Die Zusammenarbeit war in jeder Hinsicht kompetent, zuverlässig und schnell. Take a look: pvpictures.de</p>
            <p className='company_name'>PV Pictures</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-jaeckle.png"} alt="Laptop" />
            <h4>Steffen Jäckle</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>5 Sterne – Alles top! Sehr schnelle Reaktions- und Umsetzungszeit + unkomplizierte Kommunikation: So sollte es sein!</p>
            <p className='company_name'>Jäckle Consulting</p>
		</div>

       
        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-standart.png"} alt="Laptop" />
            <h4>Chantal Pfund</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Von Anfang bis Ende war unsere Erfahrung professionell, reaktionsschnell und wirklich freundlich. Es wurde sich die Zeit genommen, unsere Vorstellungen zu verstehen und gaben uns wertvolle Einblicke und Vorschläge, wie man die Website noch besser machen kann. Das Endergebnis war eine schöne Website, die unsere Erwartungen übertroffen hat. Ich kann diese Webdesign-Agentur jedem wirklich jedem weiterempfehlen.</p>
            <p className='company_name'>Unternehmensgruppe Rong</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-bb.png"} alt="Laptop" />
            <h4>Bica Both</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>Ich bin mehr als zufrieden mit dem Design, der Zusammenarbeit und der Verwirklichung meiner Wünsche. Ich liebe es und kann es nur weiterempfehlen!</p>
            <p className='company_name'>Biancaboth.de</p>
		</div>

        <div class="testimonial">
            <div className='test_user'>
			<img src={process.env.PUBLIC_URL + "/images/banner/profile-jaeckle.png"} alt="Laptop" />
            <h4>Steffen Jäckle</h4>
            {/* <p>Webvizion - Frontendentwickler</p> */}
            </div>
            <p className='testimonial_text'>5 Sterne – Alles top! Sehr schnelle Reaktions- und Umsetzungszeit + unkomplizierte Kommunikation: So sollte es sein!</p>
            <p className='company_name'>Jäckle Consulting</p>
		</div>

	</div>
    </div>
</div>
</div>

</div>
</div>
  )
}

export default BannerTen