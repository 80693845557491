import React from 'react'
import './bannerNine.scss'

const BannerNine = () => {
  return (
    <div className='nine_section'>
         <div className='nine_container'>
            <div className='nine_content'>
                <h1>Unser Service.</h1> 
                <p>Webdesign, das überzeugt: Kunden online ansprechen und binden.</p> 
            </div>
            <div className='parent_grid'>
                <div class="item-grid">
                  <h4>Webdesign</h4>
                  <p>Innovative Websites ganz nach deiner Vorstellung. </p>
                  <img className="mobile-only" src={process.env.PUBLIC_URL + "/images/banner/webdev_mobile.png"} alt="webdesign" />
                  <img className="desktop-only" src={process.env.PUBLIC_URL + "/images/banner/webdev_desktop.png"} alt="webdesign" />
                  
                </div>
                <div class="item-grid">
                    <h4>Performance</h4>
                    <p>Performante Webtechnologien für rasend schnelle Websites.</p>
                    <img className="slide-top" src={process.env.PUBLIC_URL + "/images/banner/8.png" } alt="rocket" />
                </div>
                <div class="item-grid">
                  <h4>Hosting</h4>
                  <p>Schnelles und zuverlässiges Hosting für deine Website.</p>
                  <img className="hosting" src={process.env.PUBLIC_URL + "/images/banner/server.png"} alt="hosting" />
                </div>
                <div class="item-grid">
                  <h4>SEO</h4>
                  <p>Durch ein gutes Google-Ranking erreichst du mehr Menschen.</p>
                  <img className="mobile-only" src={process.env.PUBLIC_URL + "/images/banner/Light2.png"} alt="seo-mobile" />
                  <img className="desktop-only" src={process.env.PUBLIC_URL + "/images/banner/Light.png"} alt="seo-desktop" />
                </div>
                <div class="item-grid">
                  <h4>Online Marketing</h4>
                  <p>Ein großes Spektrum an Onlinemarketing Strategien aus einer Hand an.</p>
                  <img className="mobile-only" src={process.env.PUBLIC_URL + "/images/banner/marketing_mobile.png"} alt="online-marketing" />
                  <img className="desktop-only" src={process.env.PUBLIC_URL + "/images/banner/online-marketing2.png"} alt="online-marketing-desktop" />
                </div>

                <div class="item-grid">
                  <h4>Growth Marketing</h4>
                  <p>Mehr Reichweite und Wachstum für dein Unternehmen.</p>
                  <img className="growth_hacking" src={process.env.PUBLIC_URL + "/images/banner/growth.png"} alt="growth" />
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BannerNine