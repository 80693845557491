import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        
        title: "Realisierung von individuellen Bedürfnissen",
        para: "Auf persönliche Wünsche und Zielvorstellungen wird individuell eingangen."
    },
    {
        id: 2,
        title: "Kompetente Beratung und Unterstützung",
        para: "Wir begleiten Dich auf dem Weg deine unternehmerischen Ziele zu verwirklichen."
    },
    {
        id: 3,
        
        title: "Ansprechendes und modernes Webdesign und technische Expertise",
        para: "Wir haben immer die aktuellen Trends im Blick und setzen deine Homepage mit modernsten Webtechnologien um."

    },
    {
        id: 4,
        title: "Kostengünstige Preise",
        para: "Wir garantieren für den qualitativen Service den besten Preis."
    },
    {
        id: 5,
        title: "Enge Zusammenarbeit",
        para: "Jedes Projekt findet in enger Zusammenarbeit und Absprache statt. Ob persönlich oder virtuell."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Deine Vorteile"
                    title="Warum Du mit uns arbeiten solltest?"
                    // description="Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;