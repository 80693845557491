import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import FooterTwo from '../common/footer/FooterTwo';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import AboutFour from '../component/about/AboutFour';
import BannerOne from '../component/banner/BannerOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import PricingOne from '../component/pricing/PricingOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import HeaderThree from '../common/header/HeaderThree';
import HeaderOne from '../common/header/HeaderOne';
import HeaderFour from '../common/header/HeaderFour'
import ProjectTwo from '../component/project/ProjectTwo';
import BannerThree from '../component/banner/BannerThree';
import BannerFour from '../component/banner/BannerFour';
import BannerFive from '../component/banner/BannerFive';
import BannerSix from '../component/banner/BannerSix';
import BannerSeven from '../component/banner/BannerSeven';
import BannerEight from '../component/banner/BannerEight';
import BannerNine from '../component/banner/BannerNine';
import BannerTen from '../component/banner/BannerTen';
import BannerEleven from '../component/banner/BannerEleven';
import ProjectData from "../data/project/ProjectData.json";
import { FloatingWhatsApp } from 'react-floating-whatsapp'


const DigitalAgency = () => {

    return (
        <>
        <SEO title="Website-Erstellung vom Profi"/>
        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
        <FloatingWhatsApp
        phoneNumber="4915140360833"
        accountName="Max"
        chatMessage="Hi ich bin Max👋               
        Hast du Fragen?"
        avatar="/images/banner/profile_pic1.png"
        autoOpenTimeout="10000000"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
            <HeaderThree />
            {/* <BannerSix /> */}
            <BannerEleven />
            <BannerSeven />
            <BannerEight />
            <BannerNine />
            <BannerTen />
            
          
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

