import React from 'react'
import { Link } from 'react-router-dom'
import './bannerSeven.scss'


const BannerSeven = () => {
  return (
    <div className='seven_container'>
    

        <div className='seven_content'>
            <h1>Deine eigene Website.</h1> 
            <p>SEO optimierte Website- und Onlineshop Entwicklung für dein Unternehmen. Mit neusten Webtechnologien für deinen Erfolg.</p> 
            <Link to="/contact">
            <button class="glow-on-hover bounce-top" type="button" id="button_text">Angebot anfordern</button>
            </Link>
        </div>
      <div className='seven_image'>
        <img className="desktop-only" src={process.env.PUBLIC_URL + "/images/banner/website_fashion.png"} alt="fashion" />
        <img className="mobile-only" src={process.env.PUBLIC_URL + "/images/banner/website_fashion.png"} alt="fashion" />
      </div>
    </div>
  )
}

export default BannerSeven