import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';

// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';
 

// Blog Import
import BlogGridView from './pages/BlogGrid';
import BlogArchive from './pages/Archive';
import BlogDetails from './pages/BlogDetails';

// Service 
import ServiceTwo from './pages/ServiceTwo';


// Project 

import ProjectGridTwo from './pages/ProjectGridTwo';

import ProjectDetails from './pages/ProjectDetails';

// Pages 

import AboutUs from './pages/AboutUs';

import Contact from './pages/Contact';
import ErrorPage from './pages/404';

import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
	<>
	<Helmet>
        <title>Professionelles Webdesign aus Ravensburg</title>
        <meta name="description" content="Hochwertiges Webdesign und Online Marketing Lösungen aus einer Hand zu günstigen Preisen. Ravensburg und Umgebung ✓ Günstige Preise ✓ Top Service " />
      </Helmet>
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />}/>

			

				{/* Blogs */}
				{/* <Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/> */}

				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/website-erstellung-service/"} element={<ServiceTwo />}/>
				

				{/* Project  */}
				
				<Route path={process.env.PUBLIC_URL + "/webdesign-projekte"} element={<ProjectGridTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
				
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				<Route path="*" component={<ErrorPage />} />
				{/* <Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />}/> */}
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>

			</Routes>
		</ScrollToTop>
    </Router>
	</>
  )
}

export default App;
