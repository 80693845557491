import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderThree from '../common/header/HeaderThree';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';

import { FloatingWhatsApp } from 'react-floating-whatsapp';



const Contact = () => {
    return (
        <>
            <SEO title="Starte ein Projekt" />
            <ColorSwitcher />
            <main className="main-wrapper">
            <FloatingWhatsApp
        phoneNumber="4915140360833"
        accountName="Max"
        chatMessage="Hi ich bin Max👋 
        Wie kann ich dir helfen? :)"
        avatar="/images/banner/profile_pic1.png"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
            <HeaderThree />
                <BreadCrumbOne 
                title="Contact"
                page="Contact"
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 ">
                                <h4 className="title">WhatsApp</h4>
                                <p>Wir sind jeden Tag über WhatsApp erreichbar.</p>
                                <a aria-label="Chat on WhatsApp" href="https://wa.me/4915140360833"> <img alt="Chat on WhatsApp" src="/images/banner/WhatsAppButtonWhiteSmall.png" /> </a>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <h4 className="phone-number"><a href="mailto:info@webvizion.de">info@webvizion.de</a></h4>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-info mb--30">
                                <h4 className="title">Telefon</h4>
                                <h4 className="phone-number"><a href="mailto:info@webvizion.de">+4915140360833</a></h4>
                            </div>
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--100">
                                <h4 className="title">10% für deine Empfehlung</h4>
                                <p>Schreib uns für mehr Informationen.</p>
                                <h4 className="phone-number"><a href="mailto:iinfo@webvizion.de">info@webvizion.de</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>

            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;