import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Who we are</span>
                            <h2 className="title">Building websites for world changers</h2>
                            <p>Du suchst nach einer kreativen, kostengünstigen und aufmerksamkeitsstarken Möglichkeit, den Umsatz mit deiner Website zu steigern? Dann bist du bei unserer Webdesign Agentur an der richtigen Adresse! Mit Sitz in Ravensburg bieten wir dir ein persönliches Gespräch, um die perfekte Website für dein Unternehmen zu gestalten. Egal ob du eine einfache Broschüren-Website oder eine etwas komplexere Website suchst, wir haben die perfekte Lösung für dich.</p>
                            <Link to="/contact" className="axil-btn btn-fill-primary btn-large">Angebot einholen</Link>
                        </div>
                        {/* <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="10" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="100" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;