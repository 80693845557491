import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderThree from '../common/header/HeaderThree';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import AboutThree from '../component/about/AboutThree';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import PricingOne from '../component/pricing/PricingOne';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const ServiceTwo = () => {


    return (
        <>
        <SEO title="Websites, Online Marketing & Branding" />

        <ColorSwitcher />
        <main className="main-wrapper">
        <FloatingWhatsApp
        phoneNumber="4915140360833"
        accountName="Max"
        chatMessage="Hi ich bin Max👋 
        Wie kann ich dir helfen? :)"
        avatar="/images/banner/profile_pic1.png"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
        <HeaderThree />
            <BcrumbBannerOne 
                title="Das Beste für dein Unternehmen"
                paragraph ="Professionelle Internetauftritte für Unternehmen jeder Art."
                styleClass=""
                mainThumb="/images/banner/aboutrocket.png"
                
            />
            {/* <CounterUpTwo /> */}
            <div className="section section-padding bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="Service"
                        title="Dienstleistungen"
                        description=""
                        textAlignment="heading-left"
                        textColor=""
                    />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="6" marginTop="no" />
                    </div>
                </div>
            </div>

            <AboutThree />
            
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceTwo;